.input {
  [class^="MuiInputBase-root"] {
    margin: 16px 0 8px 0;
    background-color: transparent;
    border: 2px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    input {
      padding-top: 19px;
      padding-bottom: 18px;
    }
  }
  [class~="Mui-error"] {
    border-color: #f44336;
  }
  [class~="Mui-focused"] {
    background-color: transparent;
    border-color: #3f51b5;
  }
}

