.max-w-xl {
  max-width: 36rem;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.relative {
  position: relative;
}

.hidden {
  display: none;
}
.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid {
  display: grid;
}

/* width */

.w-full {
  width: 100%;
}

/* margin */

.mr-3-important {
  margin-right: 0.75rem !important;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-3-important {
  margin-left: 0.75rem !important;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-0 {
  margin-bottom: 0;
}